<template>
  <v-container>
    <!-- <div class="product-details">
      <h1>This is the product details page</h1>
    </div> -->
    <div class="my-container" v-if="showProductContainer">
      <!-- <v-banner two-line elevation="2">
        <v-avatar
          slot="icon"
          color="red darken-2"
          size="40"
        >
          <v-icon
            icon="mdi-package-variant-closed"
            color="white"
          >
            mdi-package-variant-closed
          </v-icon>
        </v-avatar>

        Three line text string example with two actions. One to two lines
        is preferable. Three lines should be considered the maximum
        string length on desktop in order to keep messages short and actionable.

        <template v-slot:actions>
          <v-btn
            text
            color="deep-purple accent-4"
          >
            Action
          </v-btn>
          <v-btn
            text
            color="deep-purple accent-4"
          >
            Action
          </v-btn>
        </template>
      </v-banner> -->

      <v-card
        class="mx-auto"
        max-width="344"
        v-if="!reveal2"
      >
        <v-card-text>
          <div>
            <v-icon
              icon="mdi-package-variant-closed"
            >
              mdi-package-variant-closed
            </v-icon>
            <template v-if="showProductDetails">Pack Weight</template>
            <template v-else>QRC Weight</template>
          </div>
          <p class="display-1 text--primary">
            {{ productInfo.weight }} g
          </p>
          <div class="text--primary">
            <v-icon color="teal">mdi-information-variant</v-icon>
            <template v-if="showProductDetails">
              Compare the pack weight printed on the box to
              verify and continue to check genuineness of the product.
            </template>
            <template v-else>
              Compare the actual pack weight printed on the
              box with QRC weight for genuine product.
            </template>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="deep-purple accent-4"
            @click="reveal2 = true"
            v-if="showProductDetails"
          >
            Continue
            <v-icon right>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-expand-transition> -->
      <v-fade-transition>
        <!-- class="transition-fast-in-fast-out" -->
        <div
          v-if="reveal2"
          transition="fade-transition"
        >
      <v-alert
        text
        border="left"
        :type=alertType
      >
        {{ alertMsg }}
      </v-alert>
      <div class="d-flex justify-space-between py-1" v-if="showProductDetails">
        <v-card class="mx-auto" max-width="344">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-text>
                <div>Product Information</div>
                <p class="text-h6 text--primary">
                  <!-- V44 C Style &amp; V Style -->
                  {{ productInfo.name }}
                </p>
                <!-- <p>adjective</p> -->
                <div class="text--primary">
                  <!-- Fold Flat &dash; Pocket friendly style -->
                  {{ productInfo.desc1 }}
                  <!-- Pocket friendly style<br/>
                  Option of inside nose clip -->
                </div>
              </v-card-text>
            </div>

            <v-avatar class="ma-3" size="125" tile>
              <!-- <v-img
                src="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
              /> -->
              <!-- <v-img :src="require('../assets/mask.svg')" /> -->
              <v-img v-bind:src="require('../assets/' + productInfo.image)" />
            </v-avatar>
          </div>

          <v-card-actions>
            <v-btn text color="deep-purple accent-4" @click="reveal = !reveal">
              View Details
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              icon
              @click="reveal = !reveal"
            >
              <v-icon>{{ reveal ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="reveal">
              <v-divider></v-divider>

              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <!-- <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Calories</th>
                    </tr>
                  </thead> -->
                  <tbody>
                    <tr v-for="item in productDetails" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-expand-transition>
          <!-- <v-expand-transition>
            <v-card
              v-if="reveal"
              class="transition-fast-in-fast-out v-card--reveal"
              style="height: 100%;"
            >
              <v-card-text class="pb-0">
                <p class="display-1 text--primary">
                  Origin
                </p>
                <p>
                  late 16th century (as a noun denoting a place where alms were
                  distributed): from medieval Latin eleemosynarius, from late Latin
                  eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’
                </p>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn
                  text
                  color="teal accent-4"
                  @click="reveal = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition> -->
        </v-card>
      </div>
        </div>
      <!-- </v-expand-transition> -->
      </v-fade-transition>
    </div>

              <!-- <p>adjective</p> -->
    <!-- <div class="d-flex justify-space-between py-1">
      <v-card class="mx-auto" max-width="344">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-text>
              <div>Product Information</div>

              <p class="text-h6" style="color:#d1172b;margin-block-end:0">Alert!</p>

              <p>This is counterfeit product.</p>
            </v-card-text>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-img :src="require('../assets/V44_C_Style_V_Style.png')" />
          </v-avatar>
        </div>
      </v-card>
    </div> -->

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- <div class="d-flex justify-space-between py-1">
      <v-card
        class="mx-auto"
        max-width="344"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              OVERLINE
            </div>
            <v-list-item-title class="headline mb-1">
              Headline 5
            </v-list-item-title>
            <v-list-item-subtitle>
              Greyhound divisely hello coldly fonwderfully
              asd asd sa dsajdlfjdsalkfj asfsdf dsf dsaf asd
              sdaf dsaf fdsf dsaf dasf asf afdas fasfasdf
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar
            tile
            size="100"
            class="ma-3"
          >
            <v-img
              :src="require('../assets/V44_C_Style_V_Style.png')"
            />
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn
            outlined
            rounded
            text
          >
            Button
          </v-btn>
        </v-card-actions>
      </v-card>
    </div> -->
  </v-container>
</template>

<script>
import productApi from '@/api/product'

const moment = require('moment')

export default {
  data: () => ({
    reveal: false,
    reveal2: false,
    snackbar: false,
    snackbarText: null,
    alertType: 'success',
    alertMsg: 'Genuine Product',
    showProductDetails: false,
    showProductContainer: false,
    productDetails: [],
    productInfo: {
      name: 'Mask',
      desc1: 'Fold Flat - Pocket friendly style',
      image: 'mask.svg',
      weight: 0,
    },
    // aabb: 'V44_C_Style_V_Style.png',
  }),
  created() {
    // console.log(this.$route.params.s)
    // this.fetchProductInfo(this.$route.query.s)
    const s = this.$route.query.s || this.$route.params.s
    this.fetchProductInfo(s)
  },
  methods: {
    fetchProductInfo(qrData) {
      this.productDetails = []
      productApi.findByQRCode(qrData)
        .then((response) => {
          console.log(response)
          const res2 = response.data
          if (res2.status === 200 && !res2.error) {
            const data = res2.result
            console.log(data)

            this.productInfo.name = data.name
            this.productInfo.desc1 = data.desc1
            this.productInfo.image = data.imageName ?? 'mask.svg'
            this.productInfo.weight = data.quantity ?? 0

            this.productDetails.push({ key: 'batchNumber', name: 'Batch Number', value: data.batchNumber })
            this.productDetails.push({ key: 'itemCode', name: 'Item Code', value: data.itemCode })
            this.productDetails.push({ key: 'desc2', name: 'Approval', value: data.desc2 })
            this.productDetails.push({ key: 'billDate', name: 'Mfg. Date', value: moment.utc(data.billDate).format('DD-MM-yyyy') })
            this.productDetails.push({ key: 'quantity', name: 'Weight', value: `${data.quantity} g` })

            this.showProductDetails = true
          } else {
            const qrItems = qrData.split('_')
            if (qrItems.length > 0) {
              this.productInfo.weight = parseInt(qrItems[qrItems.length - 1], 0) ?? 0
            }

            // print error
            this.alertType = 'error'
            this.alertMsg = 'Counterfeit Product'
          }
          // const dt = JSON.parse(data);
          // console.log(dt);

          this.showProductContainer = true
        })
        .catch((err) => {
          console.log(err);
          this.snackbarText = err
          this.snackbar = true
        })
    },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
